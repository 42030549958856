import React from "react"

export default ({ className }) => {
    return (
        <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0, 0, 240, 240">
            <path d="M 0 12 
                Q 0 0, 12 0
                H 42 
                V 130 
                Q 42 198, 112 198
                V 180
                Q 60 180, 60 130
                V 60
                H 100
                V 42
                H 60
                V 0
                H 120
                Q 240 0, 240 120
                V 228
                Q 240 240, 228 240
                H 150
                V 118
                H 190
                V 100
                H 150
                V 90
                Q 150 50, 198 60
                V 42
                Q 132 30, 132 90
                V 240
                H 120
                Q 0 240, 0 120
                Z"/>
        </svg>
    )
}