import React from "react"
import Styles from "./awip.module.css"

export default () => {
    return (
        <div className={Styles.awip} role="img" title="a work in progress">
            <span className={Styles.awipCharacter} style={{animationDelay: `1.00s`}}>a</span>
            <span className={Styles.awipCharacter} style={{animationDelay: `1.05s`}}>&nbsp;</span>
            <span className={Styles.awipCharacter} style={{animationDelay: `1.10s`}}>w</span>
            <span className={Styles.awipCharacter} style={{animationDelay: `1.15s`}}>o</span>
            <span className={Styles.awipCharacter} style={{animationDelay: `1.20s`}}>r</span>
            <span className={Styles.awipCharacter} style={{animationDelay: `1.25s`}}>k</span>
            <span className={Styles.awipCharacter} style={{animationDelay: `1.30s`}}>&nbsp;</span>
            <span className={Styles.awipCharacter} style={{animationDelay: `1.35s`}}>i</span>
            <span className={Styles.awipCharacter} style={{animationDelay: `1.40s`}}>n</span>
            <span className={Styles.awipCharacter} style={{animationDelay: `1.45s`}}>&nbsp;</span>
            <span className={Styles.awipCharacter} style={{animationDelay: `1.50s`}}>p</span>
            <span className={Styles.awipCharacter} style={{animationDelay: `1.55s`}}>r</span>
            <span className={Styles.awipCharacter} style={{animationDelay: `1.60s`}}>o</span>
            <span className={Styles.awipCharacter} style={{animationDelay: `1.65s`}}>g</span>
            <span className={Styles.awipCharacter} style={{animationDelay: `1.70s`}}>r</span>
            <span className={Styles.awipCharacter} style={{animationDelay: `1.75s`}}>e</span>
            <span className={Styles.awipCharacter} style={{animationDelay: `1.80s`}}>s</span>
            <span className={Styles.awipCharacter} style={{animationDelay: `1.85s`}}>s</span>
        </div>
    )
}