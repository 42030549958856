import React from "react"
import Identity from './identity'
import Styles from "./header.module.css"

export default () => {
  return ( 
    <header role = "banner">
      <Identity />
    </header>
  )
}