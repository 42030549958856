import React from "react"
import { Link } from "gatsby"
import Logo from "./logo"
import Styles from "./identity.module.css"

export default () => {
    return (
        <Link to="/" title="home" className={Styles.identity}>
            <Logo className={Styles.logo}/>
            <span className={Styles.owner}>trevor fawcett</span><br/>
            <span className={Styles.purpose}>a work in progress</span>
        </Link>
    )
}