import React from "react"
import Header from "../components/header"
import Logo from "../components/logo"
import Footer from "../components/footer"
import Awip from "../components/awip"
import Styles from "./layout.module.css"

export default ({children}) => {
    return (
        <div>
            <Header/>
            <main role="main">
                {children}
            </main>
            <Footer/>
            <Logo className={Styles.background}/>
            <Awip/>
        </div>
    )
}